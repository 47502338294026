import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { SummaryCards } from './SummaryCards'
import { Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  summary: {
    flexGrow: 1,
    height: '100%',
    padding: theme.spacing(2),
    minWidth: `calc(20% - ${theme.spacing(1)}px)`,
  },
  h6: {
    lineHeight: '1.125rem',
  },
}))

const StatHeader = ({ fetching, t, selectedGroup, kpis, type }) => {
  const classes = useStyles()

  let text = {
    allGroup: '',
    allReports: '',
  }

  if (selectedGroup === t('all')) {
    text.allGroup = t('all groups')
    // text.allReports = ' ' + t('summaries:ofAllReports')
  } else {
    text.allGroup = selectedGroup + ' ' + t('group').toLowerCase()
  }

  let headerTexts = []

  switch (type) {
    case 'groups':
      headerTexts = [
        {
          name: t('dashboard:widgets.groups.title'),
          subtitle: t('summaries:allGroups'),
        },
        {
          name: t('summaries:mentalLoadAvg'),
          subtitle: `(${t('summaries:avgOf')} ${t('all groups')})`,
        },
        {
          name: t('summaries:phyLoadAvg'),
          subtitle: `(${t('summaries:avgOf')} ${t('all groups')})`,
        },
        {
          name: t('summaries:sessionTime'),
          subtitle: `(${t('summaries:avgOf')} ${text.allGroup})`,
        },
        {
          name: t('summaries:avgKcalH'),
          subtitle: `(${t('summaries:avgOf')} ${(kpis.avgKcal / 60).toFixed(
            2,
          )}  ${t('summaries:kcalMin')})`,
        },
      ]

      break
    case 'reports':
      headerTexts = [
        {
          name: t('dashboard:widgets.reports.title'),
          subtitle: t('summaries:numOfRep'),
        },
        {
          name: t('summaries:mentalLoadAvg'),
          subtitle: `(${t('summaries:avgOf')} ${text.allGroup}${
            text.allReports
          })`,
        },
        {
          name: t('summaries:phyLoadAvg'),
          subtitle: `(${t('summaries:avgOf')} ${text.allGroup}${
            text.allReports
          })`,
        },
        {
          name: t('summaries:sessionTime'),
          subtitle: `(${t('summaries:avgOf')} ${text.allGroup})`,
        },
        {
          name: t('summaries:avgKcalH'),
          subtitle: `(${t('summaries:avgOf')} ${(kpis.avgKcal / 60).toFixed(
            2,
          )} ${t('summaries:kcalMin')})`,
        },
      ]
      break
    default:
      break
  }

  // card order
  const cardsSummary = [
    {
      name: headerTexts[3].name,
      val: (kpis.avgDur || 0) + ' ' + t('hoursShort'),
      color: 'black',
      subtitle: headerTexts[3].subtitle,
    },
    {
      name: headerTexts[1].name,
      val: kpis.avgMental || 0.0,
      color: '',
      subtitle: headerTexts[1].subtitle,
    },
    {
      name: headerTexts[2].name,
      val: kpis.avgPhy || 0.0,
      color: '',
      subtitle: headerTexts[2].subtitle,
    },
    {
      name: headerTexts[4].name,
      val: parseFloat(kpis.avgKcal || 0).toFixed(),
      color: 'black',
      subtitle: headerTexts[4].subtitle,
    },
  ]

  if ('optionalKPI' in kpis) {
    cardsSummary.unshift({
      name: headerTexts[0].name,
      val: kpis.optionalKPI,
      color: 'black',
      subtitle: headerTexts[0].subtitle,
    })
  }

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="stretch"
      spacing={2}
      className={classes.root}
    >
      {cardsSummary.map((card, i) => {
        return (
          <Grid item key={i} style={{ flexGrow: 1 }}>
            <SummaryCards
              key={i}
              metrics={[
                {
                  title: card.name,
                  value: fetching ? null : card.val,
                  subtitle: card.subtitle,
                  color: card.color,
                },
              ]}
              classes={[classes.summary]}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

StatHeader.propTypes = {
  fetching: PropTypes.bool,
  t: PropTypes.func.isRequired,
  selectedGroup: PropTypes.string.isRequired,
  kpis: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
}

const translated = withTranslation(['global'])(StatHeader)

export default translated
