import { fetchWithErrors, withParams } from '../utils'
import { Endpoints } from '../constants'

/**
 * Filter parametrization
 * @param {String} searchString optional for worker name
 * @param {String} groupCode
 * @param {Number} rowsPerPage
 * @param {Number} currentPage
 * @param {String} startDate format YYYY-MM-DD
 * @param {String} endDate foormat YYYY-MM-DD
 */

export const fetchReports = async (
  searchString,
  groupCode,
  rowsPerPage,
  currentPage,
  startDate,
  endDate,
) =>
  fetchWithErrors(
    withParams(Endpoints.Record.Urls.List, {
      group_code: groupCode,
      search_string: searchString,
      record_date_start: startDate,
      record_date_end: endDate,
      per_page: rowsPerPage,
      page: currentPage + 1,
    }),
  )
