import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import ReportsListTable from './ReportsListTable'
import { withTranslation } from 'react-i18next'
import Routes from '../../../../constants/Routes'
import { Box, Button } from '@material-ui/core'
import { fetchReports } from '../../../../api/api.reports'
import {
  Endpoints,
  DEFAULT_PAGINATION_OPTIONS,
  AppTestIds,
} from '../../../../constants'
import { SearchByNameInput, GroupDropdown } from '../../../molecules'
import { _ } from '../../../../lib'
import StatHeader from '../../../molecules/StatHeader'
import PropTypes from 'prop-types'
import { createRoute, fetchWithErrors, getSummaries } from '../../../../utils'
import DateRangePicker from '../../DateRangePicker/DateRangePicker'
import { connect } from 'react-redux'
import {
  toggleDateMenu,
  clearFilters,
} from '../../../../redux/reports/reports.actions'

class ReportsList extends PureComponent {
  state = {
    loadedList: false,
    fetching: false,
    fetchError: false,
    reports: [],
    pagination: {
      rowsPerPage: DEFAULT_PAGINATION_OPTIONS.rowsPerPage,
      currentPage: 0,
      total: 0,
    },
    dateMenuHandle: null,
    groupKPI: {
      loaded: false,
      avg_duration: 0,
      avg_kcal: 0,
      avg_mental: 0,
      avg_physical: 0,
    },
  }

  async componentDidMount() {
    this.fetchReportsList(this.props.reportsQuery)
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      pagination: {
        rowsPerPage: prevRowsPerPage,
        currentPage: prevCurrentPage,
      },
    } = prevState

    // Redux prev props
    const {
      reportsQuery: {
        groupCode: prevPropGroupCode,
        searchString: prevPropssearchString,
        startDate: prevPropStartDate,
        endDate: prevPropEndDate,
      },
    } = prevProps

    const {
      pagination: { rowsPerPage, currentPage },
    } = this.state

    // Redux current props
    const {
      reportsQuery: {
        groupCode: propGroupCode,
        searchString: propsearchString,
        startDate: propStartDate,
        endDate: propEndDate,
      },
    } = this.props

    // update if page changes or if filter changes
    if (
      prevRowsPerPage !== rowsPerPage ||
      prevCurrentPage !== currentPage ||
      prevPropGroupCode !== propGroupCode ||
      prevPropssearchString !== propsearchString ||
      prevPropStartDate !== propStartDate ||
      prevPropEndDate !== propEndDate
    ) {
      this.fetchReportsList(this.props.reportsQuery)
    }
  }

  componentWillUnmount() {
    this.unmount = true
  }

  handleChangePage = (e, page) => {
    const { pagination } = this.state
    this.setState({ pagination: { ...pagination, currentPage: page } })
  }

  handleChangeRowsPerPage = (e) => {
    const rowsPerPage = e.target.value
    const oldState = _.cloneDeep(this.state)
    this.setState({ oldState, pagination: { currentPage: 0, rowsPerPage } })
  }

  fetchReportsList = async (props) => {
    const { pagination } = this.state

    !this.unmount && this.setState({ fetching: true })

    let groupKPI

    const rootResponse = await fetchReports(
      props.searchString,
      props.groupCode === 'all' ? null : props.groupCode,
      pagination.rowsPerPage,
      pagination.currentPage,
      props.startDate,
      props.endDate,
    )

    groupKPI = await this.fetchGroupKPI(props.groupCode)

    if (!('error' in rootResponse)) {
      const enclosedResponse = await rootResponse.response

      if (!('error' in rootResponse)) {
        !this.unmount &&
          this.setState({
            reports: enclosedResponse.data,
            pagination: { ...pagination, total: enclosedResponse.total },
            loadedList: true,
            fetching: false,
            groupKPI: { loaded: true, ...groupKPI },
          })
      } else {
        !this.unmount &&
          this.setState({
            fetching: false,
            fetchError: true,
          })
      }
    } else {
      !this.unmount &&
        this.setState({
          fetching: false,
          fetchError: true,
        })
    }
  }

  fetchGroupKPI = async (selectedGroup) => {
    const rootResponse = await fetchWithErrors(Endpoints.Group.Urls.List)

    let sum = {
      avg_duration: null,
      avg_kcal: null,
      avg_mental: null,
      avg_physical: null,
    }

    // default values if there are no values for the group
    const sumDefault = {
      avg_duration: 0,
      avg_kcal: 0,
      avg_mental: 0,
      avg_physical: 0,
    }

    if (!('error' in rootResponse)) {
      const enclosedResponse = await rootResponse.response

      if (!('error' in rootResponse)) {
        return getSummaries(selectedGroup, enclosedResponse)
      } else {
        return sum
      }
    } else {
      return sumDefault
    }
  }

  triggerViewError = (error) => {
    if (error) {
      this.setState({
        fetching: false,
        fetchError: true,
      })
    }
  }

  handleClickReportRow = (e, id) => {
    const { history } = this.props
    history.push(createRoute(Routes.Reports.Detail.url, { reportId: id }))
  }

  openSelectDate = (event) => {
    const oldState = _.cloneDeep(this.state)
    this.setState({ oldState, dateMenuHandle: event.currentTarget })
    this.props.toggleDateMenu(true)
  }

  render() {
    const { fetching, pagination, reports } = this.state
    const { t, reportsQuery, toggleDateMenu, resetFilters } = this.props

    const summaryKPIs = {
      optionalKPI: this.state.pagination.total,
      avgMental: this.state.groupKPI.avg_mental,
      avgPhy: this.state.groupKPI.avg_physical,
      avgDur: this.state.groupKPI.avg_duration,
      avgKcal: this.state.groupKPI.avg_kcal,
    }

    return (
      <>
        <Helmet>
          <title>{t(Routes.Reports.title)}</title>
        </Helmet>

        <Box
          display="flex"
          flexDirection="row-reverse"
          p={1}
          m={1}
          style={{ padding: '0px', margin: '0px -8px' }}
        >
          <Box p={1}>
            <DateRangePicker
              displayDate={
                reportsQuery.dateMenuSelected === 'all'
                  ? t('selectDates')
                  : reportsQuery.dateMenuSelected
              }
              openMenuHandler={this.openSelectDate}
              closeMenuHandler={() => toggleDateMenu(false)}
              anchorMenuEl={this.state.dateMenuHandle}
              selectedDateRange={[reportsQuery.startDate, reportsQuery.endDate]}
            />
          </Box>
          <Box p={1}>
            <SearchByNameInput isFetching={this.state.fetching} />
          </Box>
          <Box p={1}>
            <GroupDropdown
              handleViewError={this.triggerViewError}
              reducer="REPORTS"
            />
          </Box>
          <Box p={1}>
            <Button
              app-testid={AppTestIds.Filters.ClearButton}
              disableElevation
              onClick={resetFilters}
              variant="contained"
            >
              {t('clearFilter')}
            </Button>
          </Box>
        </Box>

        <StatHeader
          type="reports"
          selectedGroup={reportsQuery.groupCode}
          handleViewError={this.triggerViewError}
          kpis={summaryKPIs}
          fetching={this.state.fetching}
        />

        <ReportsListTable
          fetching={fetching}
          reports={reports}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleClickReportRow={this.handleClickReportRow}
          openReport={this.openReport}
          pagination={pagination}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    reportsQuery: state.reports,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDateMenu: (isOpen) => dispatch(toggleDateMenu(isOpen)),
    resetFilters: () => dispatch(clearFilters()),
  }
}

ReportsList.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  toggleDateMenu: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  reportsQuery: PropTypes.object.isRequired,
}

const connected = connect(mapStateToProps, mapDispatchToProps)(ReportsList)

const translated = withTranslation(['global'])(connected)

export default withRouter(translated)
